import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/services";

export async function searchServices(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      //TODO DEBT
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchServicesV2(request) {
  return await ApiService.post(url + "/searchV2", request as never)
    .then((res) => {
      const data = res.data;
      //TODO DEBT
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function statementServices(request) {
  return await ApiService.post(url + "/statementServices", request as never)
    .then((res) => {
      const data = res.data;
      //TODO DEBT
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}



export async function addPaymentServiceItems(request) {
  return await ApiService.post(url + "/addPaymentServiceItems", request as never).catch(({ response }) => {
    processErrors(response);
  });
}
