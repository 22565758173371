import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuperbillCustomization = _resolveComponent("SuperbillCustomization")!
  const _component_SuperbillView = _resolveComponent("SuperbillView")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.data.mode == 'customize')
      ? (_openBlock(), _createBlock(_component_SuperbillCustomization, {
          key: 0,
          patientId: _ctx.$props.patientId,
          onGenerate: _ctx.generate
        }, null, 8, ["patientId", "onGenerate"]))
      : _createCommentVNode("", true),
    (_ctx.data.mode == 'view')
      ? (_openBlock(), _createBlock(_component_SuperbillView, {
          key: 1,
          superbillData: _ctx.data.superbillData
        }, null, 8, ["superbillData"]))
      : _createCommentVNode("", true)
  ]))
}