import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/superbill";

export async function getSuperbillEncounters(
  request: SuperbillEncountersRequest
) {
  return await ApiService.post(
    url + "/getSuperbillEncounters",
    request as never
  )
    .then((res) => {
      const data = res.data;
      //TODO DEBT
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export interface SuperbillEncountersRequest {
  dosStart: string;
  dosEnd: string;
  facilityId: string;
  patientId: string;
  payerId: string;
}

export async function getSuperbillDiagnoses(
  request: SuperbillDiagnosesRequest
) {
  return await ApiService.post(url + "/getSuperbillDiagnoses", request as never)
    .then((res) => {
      const data = res.data;
      //TODO DEBT
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export interface SuperbillDiagnosesRequest {
  codes: string[];
  facilityId: string;
}
