
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SuperbillCustomization from "@/modules/superbill/SuperbillCustomization.vue";
import SuperbillView from "@/modules/superbill/SuperbillView.vue";
import { Patient } from "@/modules/patient/patient.model";
import { PatientPayer } from "@/modules/patientPayer/patientPayer.model";
import { Facility } from "@/modules/facility/facility.model";

export interface SuperbillData {
  services: any[];
  patient: Patient | null;
  selectedInsurance?: PatientPayer | null;
  selectedFacility: Facility | null;
  statementSettings: any;
  diagnoses: string[];
  serviceDateFrom: string;
  serviceDateTo: string;
}

export interface Data {
  mode: "customize" | "view";
  services: any[] | null;
  superbillData: SuperbillData | null;
}

export default defineComponent({
  name: "Superbill",
  components: { SuperbillCustomization, SuperbillView },
  props: ["patientId"],
  setup() {
    let data = reactive<Data>({
      mode: "customize",
      services: null,
      superbillData: null,
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Superbill", [
        { buttonTitle: "Back", path: "back" },
      ]);
    });

    async function generate(superbillData: SuperbillData) {
      data.superbillData = superbillData;
      data.mode = "view";
    }

    return {
      data,
      generate,
    };
  },
});
