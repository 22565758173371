
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import moment from "moment";
import { getUTCnow } from "@/utility";
import html2pdf from "html2pdf.js";
import { SuperbillData } from "@/views/pages/MenuPages/Superbill.vue";

export interface Data {
  lengthOfVisit: number;
  superbillData: SuperbillData | null;
  todaysDate: string;
}

export default defineComponent({
  name: "SuperbillView",
  props: ["superbillData"],
  setup(props) {
    let data = reactive<Data>({
      lengthOfVisit: 0,
      todaysDate: moment.utc(getUTCnow()).format("MM/DD/yyyy"),
      superbillData: props.superbillData,
    });

    const lengthOfStay = computed(() => {
      return moment(data.superbillData?.serviceDateTo).diff(
        moment(data.superbillData?.serviceDateFrom),
        "days"
      );
    });

    function serviceAdjustment(service) {
      return service.paymentServiceItems?.reduce((acc, item) => {
        if (item.paymentExt.type === 1) {
          return acc + item.paymentExt.amountApplied;
        } else {
          return acc;
        }
      }, 0);
    }

    const totalTotalCharge = computed(() => {
      return data.superbillData?.services?.reduce((acc, service) => {
        return acc + service.totalCharges;
      }, 0);
    });

    const totalAdjustment = computed(() => {
      return data.superbillData?.services?.reduce((acc, service) => {
        return acc + serviceAdjustment(service);
      }, 0);
    });

    const totalPayment = computed(() => {
      return data.superbillData?.services?.reduce((acc, service) => {
        return acc + service.payment - serviceAdjustment(service);
      }, 0);
    });

    const totalBalanceDue = computed(() => {
      return data.superbillData?.services?.reduce((acc, service) => {
        return acc + service.balanceDue;
      }, 0);
    });

    /** Method to Generate PDF for printing */
    async function generatePdf() {
      var today = moment.utc(getUTCnow()).format("DD_MMM_yyyy");
      var opt = {
        margin: 1,
        image: { type: "jpeg", quality: 2.0 },
        filename: "superbill_" + today + ".pdf",
        jsPDF: { format: "a4", orientation: "portrait" },
        pagebreak: { mode: ["legacy", "css"] },
        html2canvas: { scale: 2 },
      };
      var main = document.getElementById("mainPdf");
      html2pdf()
        .set(opt)
        .from(main)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          const f = pdf.output("bloburl");
          let link = document.createElement('a');
          link.target = "_blank";
          link.href = pdf.output("bloburl");
          link.download = "superbill_" + today + ".pdf";
          link.click();
          window.open(pdf.output("bloburl"), "_blank");
        });
    }

    return {
      data,
      generatePdf,
      lengthOfStay,
      serviceAdjustment,
      totalTotalCharge,
      totalAdjustment,
      totalPayment,
      totalBalanceDue,
    };
  },
});
