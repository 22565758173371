
import { defineComponent, onMounted, reactive } from "vue";
import { getPanel } from "@/api/encounter.api";
import Multiselect from "@vueform/multiselect/src/Multiselect";
import { patientPayersByPatient } from "@/api/patientPayer.api";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { searchServicesV2 } from "@/api/services.api";
import { getPatient } from "@/api/patient.api";
import { useStore } from "vuex";
import { getFacility } from "@/api/facility.api";
import { getStatementSettings } from "@/api/statementSettings.api";
import {
  getSuperbillDiagnoses,
  getSuperbillEncounters,
} from "@/api/superbill.api";
import { SuperbillData } from "@/views/pages/MenuPages/Superbill.vue";

export interface Data {
  customizations: {
    facility: string;
    serviceDateFrom: string;
    serviceDateTo: string;
    insurance: string;
  };
  dict: {
    facilities: [];
    insurances: [];
  };
  superbillData: SuperbillData;
  isLoading: boolean;
}

export default defineComponent({
  name: "Superbill",
  components: { Multiselect },
  props: ["patientId"],
  setup(props, ctx) {
    const store = useStore();
    let data = reactive<Data>({
      customizations: {
        facility: "",
        serviceDateFrom: "",
        serviceDateTo: "",
        insurance: "",
      },
      dict: {
        facilities: [],
        insurances: [],
      },
      superbillData: {
        services: [],
        patient: null,
        selectedInsurance: null,
        selectedFacility: null,
        statementSettings: null,
        diagnoses: [],
        serviceDateFrom: "",
        serviceDateTo: "",
      },
      isLoading: false,
    });

    const validationRules = {
      customizations: {
        facility: {
          required: helpers.withMessage("Required", required),
        },
        serviceDateFrom: {
          required: helpers.withMessage("Required", required),
        },
        serviceDateTo: {
          required: helpers.withMessage("Required", required),
        },
        insurance: {
          required: helpers.withMessage("Required", required),
        },
      },
    };

    let v$ = useVuelidate(validationRules, data);

    onMounted(async () => {
      if (props.patientId) {
        data.superbillData.patient = await getPatient(props.patientId);
      }

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;

      data.dict.insurances = (
        await patientPayersByPatient({
          patientId: props.patientId,
          includeInactive: true,
        })
      ).sort((a, b) => a.name.localeCompare(b.name));
    });

    async function generate() {
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }

      data.isLoading = true;

      // get all data needed for superbill
      // services
      let request = {
        orderBy: ["serviceDate"],
        pageNumber: 1,
        pageSize: 10000,
        PatientId: props.patientId,
        StartDate: data.customizations.serviceDateFrom,
        EndDate: data.customizations.serviceDateTo,
        Facility: [data.customizations.facility],
        CurrentResponsibleParty: [data.customizations.insurance],
      };

      const res = await searchServicesV2(request);
      data.superbillData.services = res.data;

      // patient gender display
      if (data.superbillData.patient) {
        const sexes = store.getters.allPatientSex;
        data.superbillData.patient.sex = sexes.find(
          (sex) => sex.code === data.superbillData.patient?.sex
        )?.description;
      }

      // insurance data
      data.superbillData.selectedInsurance =
        data.superbillData.patient?.patientPayers?.find(
          (payer) => payer.payerId === data.customizations.insurance
        );

      // facility data
      data.superbillData.selectedFacility = await getFacility(
        data.customizations.facility
      );

      // statement settings
      data.superbillData.statementSettings = await getStatementSettings();
      if (data.superbillData.statementSettings.logo) {
        data.superbillData.statementSettings.logo = `data:image/png;base64,${data.superbillData.statementSettings.logo}`;
      }

      // diagnoses from encounters
      let encounters = await getSuperbillEncounters({
        patientId: props.patientId,
        dosStart: data.customizations.serviceDateFrom,
        dosEnd: data.customizations.serviceDateTo,
        facilityId: data.customizations.facility,
        payerId: data.customizations.insurance,
      });

      // iterate through encounters to get all diagnosis codes
      let diagnosisCodes: Set<string> = new Set();
      for (let encounter of encounters) {
        if (encounter.facilityEncounter) {
          if (encounter.facilityEncounter.principalDiagnosis) {
            diagnosisCodes.add(encounter.facilityEncounter.principalDiagnosis);
          }
          if (encounter.facilityEncounter.diagnosis) {
            diagnosisCodes.add(encounter.facilityEncounter.diagnosis);
          }
          if (encounter.facilityEncounter.admittingDiagnosis) {
            diagnosisCodes.add(encounter.facilityEncounter.admittingDiagnosis);
          }
        }
        if (encounter.professionalEncounter) {
          if (encounter.professionalEncounter.diagnoses) {
            let diagnoses = encounter.professionalEncounter.diagnoses.map(
              (x) => x.value
            );
            diagnoses.forEach((x) => diagnosisCodes.add(x));
          }
        }
      }

      // use diagnosis codes from encounters to get full code and description for display
      let diagnosesLookup = await getSuperbillDiagnoses({
        codes: Array.from(diagnosisCodes),
        facilityId: data.customizations.facility,
      });

      data.superbillData.diagnoses = diagnosesLookup.map(
        (x) => `${x.code} - ${x.description}`
      );

      // service date
      data.superbillData.serviceDateFrom = data.customizations.serviceDateFrom;
      data.superbillData.serviceDateTo = data.customizations.serviceDateTo;

      data.isLoading = false;
      ctx.emit("generate", data.superbillData);
    }

    return {
      data,
      generate,
      v$,
    };
  },
});
